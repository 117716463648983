<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    <CCol col="6" style="font-size: 25px;">تعديل اللون</CCol>
                </CCardHeader>
                <CCardBody>
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <form @submit.prevent="UpdateColor">
                                    <div class="form-group">
                                        <label style="padding:5px;">الاسم</label>
                                        <input type="text" class="form-control" v-model="size.name" required><!--v-model="category.name" -->
                                    </div>

                                       <div class="form-group">
                                        <label style="padding:5px;">الاسم باللغة العربية</label>
                                        <input type="text" class="form-control" v-model="size.name_ar" required><!--v-model="category.name" -->
                                    </div>
                                    <!-- <button type="submit" class="btn btn-primary" >تحديث البيانات</button> -->
                                    &emsp;
                                    <CButton color="danger" @click="goBack">العودة</CButton>
                                </form>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import swal from 'sweetalert'
    import $ from 'jquery'
    export default {
        name : 'EditColor',
        data() {
            return {
                color: [],
                image:'',
            }
        },
        created() {
            console.log(this.$route.params.id);
            this.$http
                .get(`${process.env.VUE_APP_URL}colors/${this.$route.params.id}/edit`)
                .then((response) => {
                    this.color = response.data.data;
                });
        },
        methods: {
            UpdateColor() {
                let formData = new FormData();
                formData.append('name', this.color.name);
                formData.append('name_ar', this.color.name_ar);
                formData.append('_method', 'PUT');
                console.log(formData);
                this.$isLoading(true)
                this.$http
                    .post(`${process.env.VUE_APP_URL}colors/${this.$route.params.id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                    .then((response) => {
                        this.$isLoading(false)
                        if(!response.data.error)
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                        }
                        else
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                            //this.$router.push({ path: 'colors'});
                        }
                    });
            },
            goBack() {
                this.colorsOpened ? this.$router.go(-1) : this.$router.push({path: '/colors'})
            },
        }
    }
</script>